import {
  parseCategories,
  parseCheckboxStyles,
  parseCTAButtonData,
  parseCustomGlobalStyles,
  parseFilterSections,
  parseFilterButtonsTexts,
} from 'components/AlgoliaFilters/parsers';
import { IProductListFilterData } from 'components/ProductListFilter';

import { IUmbracoProductsListConfigs } from './model';

export const productsFiltersDefaultData: IProductListFilterData = {
  showFilters: false,
  filterButtonsTexts: {
    filtersSelectLabel: 'Select',
    filtersHaveSelectedLabel: `You've selected`,
    filtersTogglerButtonText: 'Filters',
    closeButtonText: 'Close',
    seeMoreButtonText: 'See more',
    seeLessButtonText: 'See less',
  },
  categories: [],
  categorySectionTitle: '',
  filterSections: [],
  customGlobalStyles: {},
  showResultsBtn: { title: '' },
  clearAllBtn: { title: '' },
  filterMenuBgColor: null,
  checkboxStyles: {},
  currentPageId: '',
  productsListConfigs: {
    limitOnPageDesktop: 0,
    limitOnPageMobile: 0,
    isShowCTABtn: false,
    isShowLabels: false,
    CTABtn: undefined,
    sorryMessage: '',
  },
  defaultProducts: [],
  masterPageId: 0,
  masterPageLink: '',
  lang: '',
  showManuallySelectedProducts: false,
  productsLinks: [],
  removeAppliedFilterAriaLabel: '',
};

export const parseProductsFilters = (
  param: AppFilters.IUmbracoFiltersMainProperties,
  showFilters: boolean,
  isFixedFilters: boolean,
  counterFirstWord: string | undefined,
  counterMiddleWord: string | undefined,
  isArticlesCounterDisplayed: boolean,
  filtersTitle: string | undefined,
  currentPageId: string,
  configs: IUmbracoProductsListConfigs,
  products: ProductCard.IProductCard[],
  showManuallySelectedProducts: boolean,
  productsLinks: string[],
  lang: string
): IProductListFilterData => {
  const result: IProductListFilterData = {
    productsListConfigs: {
      limitOnPageDesktop: configs.productListConfigsLimitOnPage,
      limitOnPageMobile: configs.productListConfigsLimitOnPageMobile,
      isShowCTABtn: configs.productListConfigsShowCTABtn === '1',
      isShowLabels: configs.productListConfigsShowLabels === '1',
      CTABtn: configs.productListConfigsCTAButton?.length
        ? { btnStyles: configs.productListConfigsCTAButton[0].properties.btnStyles }
        : undefined,
      sorryMessage: configs.productListConfigsSorryMessage,
    },
    categories: param.categoriesList?.length
      ? parseCategories(param.categoriesList[0])
      : productsFiltersDefaultData.categories,
    refineCategories: param.refineCategories,
    currentPageId,
    categorySectionTitle: param.categoriesList?.length
      ? param.categoriesList[0].properties.categorySectionTitle
      : productsFiltersDefaultData.categorySectionTitle,
    filterSections: param.filterSections
      ? parseFilterSections(param.filterSections)
      : productsFiltersDefaultData.filterSections,
    customGlobalStyles: param.customGlobalStyles?.length
      ? parseCustomGlobalStyles(param.customGlobalStyles[0])
      : productsFiltersDefaultData.customGlobalStyles,
    showResultsBtn: param.showResultsBtn
      ? parseCTAButtonData(param.showResultsBtn[0])
      : productsFiltersDefaultData.showResultsBtn,
    clearAllBtn: param.clearAllBtn
      ? parseCTAButtonData(param.clearAllBtn[0])
      : productsFiltersDefaultData.clearAllBtn,
    filterMenuBgColor: param.filterMenuBgColor
      ? param.filterMenuBgColor[0]?.properties.colorPicker?.label || null
      : productsFiltersDefaultData.filterMenuBgColor,
    checkboxStyles: param.checkboxStyles
      ? parseCheckboxStyles(param.checkboxStyles[0])
      : productsFiltersDefaultData.checkboxStyles,
    filterButtonsTexts: param.filterButtonsTexts
      ? parseFilterButtonsTexts(param.filterButtonsTexts[0])
      : productsFiltersDefaultData.filterButtonsTexts,
    defaultProducts:
      products && products.length ? products : productsFiltersDefaultData.defaultProducts,
    masterPageId: param.masterPageId,
    masterPageLink: param.masterPageLink,
    showFilters,
    isFixedFilters,
    counterFirstWord,
    counterMiddleWord,
    isArticlesCounterDisplayed,
    filtersTitle,
    showManuallySelectedProducts,
    productsLinks,
    lang,
    removeAppliedFilterAriaLabel: param.removeAppliedFilterAriaLabel,
  };

  return result;
};
